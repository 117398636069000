import React from 'react';
import 'isomorphic-unfetch';
import initApollo from './initApollo';
import { getDataFromTree, getMarkupFromTree } from 'react-apollo';

import { cookieParser, logout } from '/lib/helpers';

const defaultApp = (App) => {
  return class Apollo extends React.Component {
    static displayName = 'withApollo(App)';

    static async getInitialProps(ctx) {
      const {
        Component,
        router,
        router: {
          query: { isPrivate, token: tokenQuery, agentClientUserId },
        },
        ctx: { res },
      } = ctx;
      let { token: tokenCookie, user_id: userId, agent_client_id: agentClientId } = cookieParser(ctx.ctx);

      if (!tokenCookie) {
        tokenCookie = undefined;
      }
      const token = tokenQuery || tokenCookie;
      const apollo = initApollo(
        {},
        {
          getToken: () => token,
          getUserId: () => userId,
          getAgentClientId: () => {
            return agentClientUserId || agentClientId;
          },
        },
      );

      ctx.ctx.apolloClient = apollo;

      let appProps = {
        userIdentified: token && token !== 'undefined' ? true : false,
      };

      if (App.getInitialProps) {
        const initialProps = await App.getInitialProps(ctx);
        appProps = {
          ...appProps,
          ...initialProps,
        };
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {};
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      if (typeof window === 'undefined') {
        try {
          // Run all GraphQL queries
          await getDataFromTree(<App {...appProps} Component={Component} router={router} apolloClient={apollo} />);

          await getMarkupFromTree({
            // eslint-disable-next-line no-undef
            renderFunction: renderToNodeStream,
            tree: <App {...appProps} Component={Component} router={router} apolloClient={apollo} />,
          });
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          if (error.networkError || (isPrivate && !appProps.userIdentified)) {
            return logout(ctx.ctx);
          }
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
        token,
        userId,
        agentClientId,
      };
    }

    constructor(props) {
      super(props);

      this.apolloClient = initApollo(props.apolloState, {
        // if the token is set after the platform loads, we'll
        // need getToken to return the cookieParser given that on
        // account creation we're setting the cookie first, then set the prop
        // token can be found at props.pageProps.token by default
        getToken: () => props.token || cookieParser().token,
        getUserId: () => props.userId || cookieParser().user_id,
        getAgentClientId: () => props.agentClientId || cookieParser().agent_client_id,
      });
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
};

export default defaultApp;
